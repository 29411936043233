import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useNewsList() {

    //User toast
    const toast = useToast()

    const refNewsListTable = ref(null)

    const tableColumns = [
        {key: 'id', label:'ID', sortable: true},
        {key: 'image_path', label:'IMAGE'},
        {key: 'title', label:'Title', sortable: true},
        {key: 'seo_link', label:'SLUG', sortable:true},
        {key: 'category_name', label:'CATEGORY', sortable:true},
        {key: 'is_active', label:'STATUS',sortable: true},
        {key: 'actions'},
    ]

    const perPage = ref(10)
    const totalNews = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    // Filter
    const languageFilter = ref(1)
    const statusFilter = ref(-1)
    const dataMeta = computed(() => {
        const localItemsCount = refNewsListTable.value ? refNewsListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalNews.value,
        }
    })

    const refetchData = () => {
        refNewsListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, languageFilter, statusFilter], () => {
        refetchData()
    })

    const fetchNews = (ctx, callback) => {
        store.dispatch('news/fetchNewss',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        //    is_active: statusFilter.value, // As defined -1 means get all news not matter it is active or not
        })
        .then(response =>{
            const {news, total} = response.data
            callback(news)
            totalNews.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching news list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchNews,
        tableColumns,
        perPage,
        currentPage,
        totalNews,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refNewsListTable,
        ability,
        refetchData,
        languageFilter,
        statusFilter,
   
    }

}